<template>
  <div id="app" class="mt-4">
    <div v-if="!disabled" class="edit-mode"></div>
    <div class="my-alert col-12 col-md-6">This mission is found</div>
    <h1 class="text-center loading" v-if="loading">loading . . .</h1>
    <div v-else>
      <div class="row">
        <div class="missions col-12 col-md-7 text-center">
          <p @click="addMission()" :key="0">
            <b-icon icon="plus-lg" font-scale="1.9"></b-icon>
          </p>
          <transition-group
            appear
            appear-class="custom-appear-class"
            appear-active-class="missions-transition"
            tag="ul"
            class="row list-unstyled"
          >
            <li
              class="col-12 col-md-4 col-lg-3"
              :key="missions.indexOf(m)"
              v-for="m in unCompletedM"
              @click="displayMission(m)"
            >
              <div class="mission py-2">
                {{ m.Title }}
              </div>
              <b-icon
                class="position-absolute"
                v-if="m.isCompleted"
                icon="check-square"
                variant="success"
                aria-hidden="true"
                @click.stop="isCompleted(m)"
              ></b-icon>
              <b-icon
                class="position-absolute"
                v-else
                @click.stop="isCompleted(m)"
                icon="check-square"
                variant="secondary"
                aria-hidden="true"
              ></b-icon>
            </li>
          </transition-group>
        </div>
        <transition
          appear
          appear-class="custom-appear-class"
          appear-active-class="displayed-mission-transition"
        >
          <div
            class="displayed-mission col-12 col-md-5"
            :class="{ edit: !disabled }"
          >
            <form v-if="missions.length" class="container">
              <label>Title :</label>
              <input
                :disabled="disabled == 1"
                type="text"
                v-model="dMission.Title"
              />
              <label>Description :</label>
              <textarea
                :disabled="disabled == 1"
                v-model="dMission.Desc"
              ></textarea>
              <b-button-group class="float-md-start float-end">
                <b-button
                  :disabled="disabled == 0"
                  v-if="disabled"
                  @click="editMission()"
                  variant="outline-warning"
                >
                  <b-icon
                    icon="pencil-square"
                    font-scale="1.3"
                    aria-hidden="true"
                  ></b-icon>
                </b-button>
                <b-button
                  :disabled="disabled == 1"
                  v-if="!disabled"
                  variant="outline-primary"
                  @click="saveMission(dMission)"
                >
                  <b-icon
                    icon="file-earmark-check"
                    font-scale="1.3"
                    aria-hidden="true"
                  ></b-icon>
                </b-button>
                <b-button @click="deleteMission()" variant="outline-danger">
                  <b-icon
                    icon="trash"
                    font-scale="1.3"
                    aria-hidden="true"
                  ></b-icon>
                </b-button>
              </b-button-group>
            </form>
            <div v-else>you have not missions</div>
          </div>
        </transition>
      </div>
      <div class="completed-missions justify col-12">
        <transition-group
          appear
          appear-class="custom-appear-class"
          appear-active-class="completed-missions-transition"
          tag="ul"
          class="d-flex justify-content-center w-100 flex-nowrap"
        >
          <li
            class="col-12 col-md-6"
            :key="missions.indexOf(m)"
            v-for="m in completedM"
            @click="displayMission(m)"
          >
            <div class="mission py-2">
              {{ m.Title }}
            </div>
            <b-icon
              class="position-absolute"
              v-if="m.isCompleted"
              icon="check-square"
              variant="success"
              aria-hidden="true"
              @click.stop="isCompleted(m)"
            ></b-icon>
            <b-icon
              class="position-absolute"
              v-else
              @click.stop="isCompleted(m)"
              icon="check-square"
              variant="secondary"
              aria-hidden="true"
            ></b-icon>
          </li>
        </transition-group>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      missions: [
        {
          id: 1,
          Title: "mission-1",
          Desc: "text-1",
          isCompleted: false,
          date: 1656512203138,
        },
        {
          id: 2,
          Title: "mission-2",
          Desc: "text-2",
          isCompleted: false,
          date: 1656512803138,
        },
        {
          id: 3,
          Title: "mission-3",
          Desc: "text-3",
          isCompleted: false,
          date: 1656512503138,
        },
        {
          id: 4,
          Title: "mission-4",
          Desc: "text-4",
          isCompleted: false,
          date: 1656512915283,
        },
        {
          id: 5,
          Title: "mission-5",
          Desc: "text-5",
          isCompleted: true,
          date: 1656512703138,
        },
        {
          id: 6,
          Title: "mission-6",
          Desc: "text-6",
          isCompleted: false,
          date: 1656512303138,
        },
        {
          id: 7,
          Title: "mission-7",
          Desc: "text-7",
          isCompleted: true,
          date: 1656512503138,
        },
        {
          id: 8,
          Title: "mission-8",
          Desc: "text-8",
          isCompleted: true,
          date: 1656512504138,
        },
      ],
      dMission: {},
      disabled: 1,
      loading: 1,
    };
  },
  mounted: function () {
    this.setLoadingTime(); //method1 will execute at pageload
  },
  methods: {
    setLoadingTime: function () {
      window.onload = () => {
        setTimeout(() => (this.loading = 0), 1000);
      };
    },
    displayMission: function (m) {
      if (this.disabled == 1) {
        m == undefined
          ? (this.dMission = this.missions[0])
          : (this.dMission = m);
      } else {
        alert(`you are editing the mission`);
      }
    },
    deleteMission: function () {
      this.missions.splice(this.missions.indexOf(this.dMission), 1);
      this.dMission = this.missions[0];
      this.disabled == 0 ? (this.disabled = 1) : ``;
    },
    editMission: function () {
      this.disabled = 0;
      if (this.missions.indexOf(this.dMission) == -1) {
        this.dMission = this.missions[0];
      }
    },
    saveMission: function (m) {
      let d = this.missions.indexOf(m);
      let myAlert = document.querySelector(`.my-alert`);
      if (
        this.missions.find(({ Title }, index) => {
          if (d != index) {
            return Title == m.Title;
          } else return false;
        })
      ) {
        myAlert.animate(
          [
            { top: ` -33%` },
            { top: ` 20px` },
            { top: ` 20px` },
            { top: ` 20px` },
            { top: ` 20px` },
            { top: ` 20px` },
            { top: ` 20px` },
            { top: ` 20px` },
            { top: ` 20px` },
            { top: ` 20px` },
            { top: ` 20px` },
            { top: ` 20px` },
            { top: ` 20px` },
            { top: ` 20px` },
            { top: ` 20px` },
            { top: ` 20px` },
            { top: ` 20px` },
            { top: ` 20px` },
            { top: ` 20px` },
            { top: ` 20px` },
            { top: ` 20px` },
            { top: ` 20px` },
            { top: ` 20px` },
            { top: ` 20px` },
            { top: ` 20px` },
            { top: ` 20px` },
            { top: ` 20px` },
            { top: ` 20px` },
            { top: ` 20px` },
            { top: ` 20px` },
            { top: ` 20px` },
            { top: ` 20px` },
            { top: ` 20px` },
            { top: ` 20px` },
            { top: ` 20px` },
            { top: ` 20px` },
            { top: ` 20px` },
            { opacity: ` 0` },
          ],
          {
            duration: 4000,
          }
        );
      } else {
        this.missions[d].Title = m.Title;
        this.disabled = 1;
      }
      if (m.Title == `` || m.Desc == ``) {
        this.disabled = 0;
        alert(`this mission is empaty`);
      }
    },
    addMission: function () {
      if (this.disabled == 1) {
        this.disabled = 0;
        this.dMission = {
          Title: ``,
          isCompleted: false,
          Desc: ``,
          date: new Date().getTime(),
        };
        this.dMission.id = this.missions.length + 1;
        this.missions.push(this.dMission);
      } else {
        alert(`you are editing the mission`);
      }
    },
    isCompleted(m) {
      m.isCompleted == true ? (m.isCompleted = false) : (m.isCompleted = true);
    },
  },
  computed: {
    unCompletedM: function () {
      return this.missions
        .filter(function (m) {
          return !m.isCompleted;
        })
        .sort((a, b) => {
          return b.date - a.date;
        });
    },
    completedM: function () {
      return this.missions
        .filter(function (m) {
          return m.isCompleted;
        })
        .sort((a, b) => {
          return b.date - a.date;
        });
    },
  },
};
</script>
